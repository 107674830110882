<template>
    <PageContainer>
        <h5>Policy</h5>
        <p>
            Coming Soon...
        </p>
    </PageContainer>
</template>

<script>
import PageContainer from "../utils/PageContainer.vue"
export default {
    components: {
        PageContainer,
    },
}
</script>

<style></style>
